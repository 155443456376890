a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}
.btn_bg button {
    text-transform: none;
}
*{
   margin: 0;
   padding: 0; 
   box-sizing: border-box;
}
